import axios from 'axios';
import { BASE_API_URL } from '../../services/api';
import axiosWithHeaders from '../../services/api';


export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    updateConfiguration({ commit }, { configId, value }) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.put(`configurations/update/${configId}/${value}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    fetchConfiguration({ commit }, configId) {
      return new Promise((resolve, reject) => {
        axios.get(`${BASE_API_URL}configurations/${configId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    fetchConfigurations({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_API_URL + 'configurations')
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    }
  };

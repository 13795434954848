import axiosWithHeaders from '../../services/api';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    createOrUpdateDocumentConfiguration({ commit }, configurationData) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post('document_configurations/create', configurationData)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    retrieveExerciceConfigurations({ commit }, documentId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(`document_configurations/retrieve/${documentId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    deleteExerciceConfiguration({ commit }, configurationId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.delete(`document_configurations/delete/${configurationId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    listExerciceConfigurations({ commit }) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get('document_configurations/list')
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    }
  };
<script>
import { Notyf } from 'notyf';
import moment from 'moment'

export default {
    methods: {
        $notyf() {
            return new Notyf({
                duration: 5000, // Durée par défaut des notifications
                position: {
                    x: 'right',
                    y: 'top',
                },
                types: [
                    {
                        type: 'default',
                        backgroundColor: '#3B7DDD',
                        icon: {
                            className: 'notyf__icon--success',
                            tagName: 'i',
                        },
                    },
                    {
                        type: 'success',
                        backgroundColor: '#28a745',
                        icon: {
                            className: 'notyf__icon--success',
                            tagName: 'i',
                        },
                    },
                    {
                        type: 'warning',
                        backgroundColor: '#ffc107',
                        icon: {
                            className: 'notyf__icon--error',
                            tagName: 'i',
                        },
                    },
                    {
                        type: 'danger',
                        backgroundColor: '#dc3545',
                        icon: {
                            className: 'notyf__icon--error',
                            tagName: 'i',
                        },
                    },
                ],
            });
        },

        $errorNotyf(error) {
            if (error.message) {
                this.$notyf().open({
                    type: 'danger',
                    message: error.message,
                    dismissible: true
                });
            } else if (typeof error === 'object') {
                const errors = { ...error };

                const notifications = [];

                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        notifications.push({
                            type: 'danger',
                            message: `${key.toUpperCase()} : ${errors[key]}`,
                            dismissible: true,
                        });
                    }
                }

                // Afficher les notifications
                for (const notification of notifications) {
                    this.$notyf().open(notification);
                }
            } else if (Array.isArray(error)) {
                // Si error est un tableau d'erreurs
                for (const errorMsg of error) {
                    this.$notyf().open({
                        type: 'danger',
                        message: errorMsg,
                        dismissible: true
                    });
                }
            }
        },

        $successNotyf(message, type='success') {
            this.$notyf().open({
                type: type,
                message: message,
                dismissible: true
            });
        },
        $getCurrentDate() {
            const currentDate = new Date();
            return moment(currentDate).format("YYYY-MM-DD");
        },
        $getYearRange() {
            var currentYear = new Date().getFullYear();
            var minYear = currentYear;
            var maxYear = minYear + 100;
            return { min: minYear, max: maxYear };
        },
        $isDateInPast(date) {
            const inputDate = new Date(date)
            const today = new Date();
            return today.getTime() >= inputDate.getTime();
        },
        $currentMonth(){
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            return currentMonth + 1
        },
        $getMonthName(monthNumber) {
            const months = [
                "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
                "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
            ];
            return months[monthNumber - 1];
        },
        $getFirstAndLastDateOfYear(){
            const currentYear = new Date().getFullYear();
            const firstDate = new Date(currentYear, 0, 1);
            const lastDate = new Date(currentYear, 11, 31);

            return {
                firstDate: moment(firstDate).format("DD-MM-YYYY"),
                lastDate: moment(lastDate).format("DD-MM-YYYY"),
            };
        },
        $formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
    }
}
</script>
<template>
    <footer class="footer">
        <b-container fluid>
            <b-row>
                <b-col col sm="6">
                    {{ new Date().getFullYear() }} © {{ config?.app_name }}.
                </b-col>
                <b-col col sm="6">
                    <div class="text-sm-end d-none d-sm-block">
                        {{ config?.app_slogan }}
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            config: null
        };
    },
    async mounted() {
        await this.configurations()
    },
    methods: {
        ...mapActions('configuration', ['fetchConfigurations']),
        async configurations() {
            try {
                const response = await this.fetchConfigurations();
                this.config = response;
            } catch (error) {
                console.error(error);
                this.config = null
            }
        },
    },
}
</script>
import axiosWithHeaders from '../../services/api';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    createLog({ commit }, { userId, action }) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post(`logs/create/${userId}/${action}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    retrieveLog({ commit }, logId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(`logs/retrieve/${logId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    updateLog({ commit }, { logId, action }) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.put(`logs/update/${logId}/${action}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    deleteLog({ commit }, logId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.delete(`logs/delete/${logId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    listLogs({ commit }) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get('logs/list')
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    }
  };
import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../../app.config";
import jwt_decode from 'jwt-decode'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

const isTokenValid = () => {
  const token = localStorage.getItem('access_token')
  if (!token) {
    return false
  }

  const decoded = jwt_decode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired) && !isTokenValid()) {
    return next('/login')
  }
  return next()
})


// router.beforeResolve(async (routeTo, routeFrom, next) => {
//   // Create a `beforeResolve` hook, which fires whenever
//   // `beforeRouteEnter` and `beforeRouteUpdate` would. This
//   // allows us to ensure data is fetched even when params change,
//   // but the resolved route does not. We put it in `meta` to
//   // indicate that it's a hook we created, rather than part of
//   // Vue Router (yet?).

//   document.title = routeTo.meta.title + ' | ' + appConfig.title;
//   // If we reach this point, continue resolving the route.
//   next();
// });

export default router;

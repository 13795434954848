import axiosWithHeaders from '../../services/api';

export const state = {
    permissionList: []
  };
  
  export const getters = {
    getPermissions: state => state.permissionList
  };
  
  export const mutations = {
    setPermissions(state, permissions) {
      state.permissionList = permissions;
    }
  };

export const actions = {
    addRole({
        commit
    }, roleData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post('roles/add', roleData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    fetchRoles({
        commit
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('roles')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    fetchRole({
        commit
    }, roleId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`roles/${roleId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    updateRole({
        commit
    }, {
        roleId,
        roleData
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`roles/${roleId}/update`, roleData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    deleteRole({
        commit
    }, roleId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete(`roles/${roleId}/delete`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    attachPermissionsToRole({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`roles/${data.role_id}/attach_permissions`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    removePermissionsToRole({
        commit
    },data) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete(`roles/${data.role_id}/remove_permissions`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    attachRole({
        commit
    }, {
        userId,
        roleId
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`users/${userId}/roles/${roleId}/attach`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    removeRole({
        commit
    }, {
        userId,
        roleId
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`users/${userId}/roles/${roleId}/remove`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    addSpecificPermissionToUser({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`users/${data.user_id}/add_permission/${data.permission_id}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    removeSpecificPermissionToUser({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`users/${data.user_id}/remove_permission/${data.permission_id}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    getUserPermissions({
        commit
    }, userId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`users-permissions/${userId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    getRolePermissions({
        commit
    }, roleId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`roles-permissions/${roleId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    async permissions({
        commit
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`permissions`)
                .then(response => {
                    commit('setPermissions', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
};
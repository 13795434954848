import AppLayout from '@/layouts/main.vue'
export default [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login",
      authRequired: false,
    },
    component: () => import("../views/account/login.vue"),
  },
  {
    path: "/forget-pass",
    name: "forget-pass",
    meta: {
      title: "ForgetPass",
      authRequired: false,
    },
    component: () => import("../views/account/forget-pass.vue"),
  },
  {
    path: "/success-reset",
    name: "success-reset",
    meta: {
      title: "SuccessRest",
      authRequired: false,
    },
    component: () => import("../views/account/success-reset.vue"),
  },
  {
    path: "/",
    meta: { authRequired: true },
    component: AppLayout,
    children: [
      {
        path: "/",
        name: "documents-list",
        meta: { title: "Liste des documents" },
        component: () => import("../views/documents/list-view"),
      },
      {
        path: "/exercices-list-view",
        name: "exercices-list-view",
        component: () => import("../views/exercices/list-view")
      },
      {
        path: "/exercices-list-detail/:id",
        name: "exercices-list-detail",
        meta: { title: "Détails d'un exercice" },
        component: () => import("../views/exercices/details"),
      },
      {
        path: "/exercices-list-add",
        name: "exercices-list-add",
        meta: { title: "Ajouter/Modifier exercice" },
        component: () => import("../views/exercices/add"),
      },
      {
        path: "/contacts",
        name: "contacts",
        meta: { title: "Contacts" },
        component: () => import("../views/contact/contact.vue"),
      },
      {
        path: "/statistiques",
        name: "statistiques",
        meta: { title: "Statistiques" },
        component: () => import("../views/statistique/statistique.vue"),
      },
      {
        path: "/reports",
        name: "reports",
        meta: { title: "Rapports" },
        component: () => import("../views/statistique/report.vue"),
      },
      {
        path: "/roles",
        name: "roles",
        meta: { title: "Roles" },
        component: () => import("../views/role/role.vue"),
      },
      {
        path: "/users-list-view",
        name: "users-list-view",
        meta: { title: "Liste des utilisateurs" },
        component: () => import("../views/team/team.vue"),
      },
      {
        path: "/users-profile/:id",
        name: "users-profile",
        meta: { title: "Profile Utilisateur" },
        component: () => import("../views/team/profile.vue"),
      },
      {
        path: "/users-profile-settings/:id",
        name: "users-settings",
        meta: { title: "Profile Utilisateur" },
        component: () => import("../views/team/profile-setting.vue"),
      },
    ]
  },
];
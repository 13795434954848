import axiosWithHeaders from '../../services/api';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    addDocument({ commit }, documentData) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post('documents/add', documentData)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    updateDocument({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.put(`documents/update/${data.id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    fetchDocument({ commit }, documentId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(`documents/${documentId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    deleteDocument({ commit }, documentId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.delete(`documents/delete/${documentId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    partnerDocuments({
      commit
    }, partnerId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(`documents/partner/${partnerId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    assigneDocumentToPartner({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post(`documents/assigne-partner`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    setDocumentStatus({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.put(`documents/status/${data.id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    activeOrUnactiveDocument({ commit }, documentId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.put(`documents/active/${documentId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
  
    updateDocumentFile({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.put(`documents/update_file/${data.get('id')}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },

    getListDocForManagerPartner({ commit }, managerId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(`documents/manager-partner-documents/${managerId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },

    getPartnerDocuments({ commit }, partnerId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(`documents/partner/${partnerId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },

    downloadFile({ commit }, data) {
      axiosWithHeaders({
        method: 'get',
        url: 'documents/download/' + data.id,
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const extension = data.file_name.split('.').pop();
    
        link.setAttribute('download', `${data.id}_${data.file_name}_.${extension}`);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.error(error);
        // Afficher un message d'erreur à l'utilisateur
      });
    }
    
  };
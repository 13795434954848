import axiosWithHeaders from '../../services/api';

export const state = {};

export const getters = {};

export const mutations = {};


export const actions = {
  fetchExercices({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('exercices/')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  addExercice({
    commit
  }, exerciceData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('exercices/add', exerciceData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  renewExercice({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('renew-exercise-for-year', data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateExercice({
    commit
  }, exerciceData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`exercices/update/${exerciceData.id}`, exerciceData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  exercice({
    commit
  }, {exerciceId, year = null}) {
    return new Promise((resolve, reject) => {
      let url = `exercices/exercice/${exerciceId}`;
      if (year) {
        url += `?year=${year}`;
      }
      axiosWithHeaders.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  deleteExercice({
    commit
  }, exerciceId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`exercices/delete/${exerciceId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  userExerciceAndDocuments({ commit }, { userId, year = null }) {
    return new Promise((resolve, reject) => {
      let url = `users-detail-data/${userId}`;
      if (year) {
        url += `?year=${year}`;
      }
      
      axiosWithHeaders.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },  

  openOrCloseExercice({
    commit
  }, exerciceId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`exercices/open-close/${exerciceId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  setExerciceDate({
    commit
  }, exerciceId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`exercices/set-date/${exerciceId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  listPartnerAndServiceWhereDocumentNotSent({
    commit
  }, {
    managerId,
    period
  }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`partners/documents/${managerId}/${period}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

import axiosWithHeaders from '../../services/api';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  associatePartnerToManager({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('associate-partner-to-manager/', data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  removeManagerPartner({ commit }, collaborationId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`remove-manager-partner/${collaborationId}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  managerPartnerLists({ commit }, managerId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`manager-partner-lists/${managerId}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getManagerPartnerDocuments({ commit }, { managerId, partnerId }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-manager-partner-documents/${managerId}/${partnerId}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getPartnerDocuments({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-partner-documents/${partnerId}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getManagerPartnerDocumentsByService({ commit }, { managerId, partnerId, serviceId }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-manager-partner-documents-by-service/${managerId}/${partnerId}/${serviceId}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getPartnerDocumentsByService({ commit }, { partnerId, serviceId }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-partner-documents-by-service/${partnerId}/${serviceId}/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
}

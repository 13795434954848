import axiosWithHeaders from '../../services/api';

export const state = {};

export const getters = {};

export const mutations = {};

const formatFilterStat = (filterData)=>{
  let url = `?startDate=${filterData.startDate}&endDate=${filterData.endDate}`;
  if (filterData.exercise_id !== undefined && filterData.exercise_id !== null) url += `&exercise_id=${filterData.exercise_id}`;
  if (filterData.user_partner !== undefined && filterData.user_partner !== null) url += `&user_partner=${filterData.user_partner}`;
  if (filterData.user_collaborateur !== undefined && filterData.user_collaborateur !== null) url += `&user_collaborateur=${filterData.user_collaborateur}`;
  return url
}

export const actions = {
  exerciseStatusDistribution({ commit }, filterData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('exercise-status-distribution' + formatFilterStat(filterData))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  documentsPerPeriodicity({ commit }, filterData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('documents-per-periodicity' + formatFilterStat(filterData))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  collaborationsPerMonth({ commit }, filterData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('collaborations-per-month' + formatFilterStat(filterData))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  documentStatusDistribution({ commit }, filterData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('exercise-status-document' + formatFilterStat(filterData))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getStatistics({ commit }, filterData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get-statistics' + formatFilterStat(filterData))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getReports({ commit }, filterData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get-reports' + formatFilterStat(filterData))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};
